
import api from '@/api/consult';

export default {
  state: {
    appointments: null,
    appointment: null,
    medicalRecord: null,
    cancelAppointment: null,
    errorMessage: null,
    dependantsAppointments: null
  },

  getters: {
    userAppointments: (state) => state.appointments,
    userDependantsAppointments: (state) => state.dependantsAppointments,
    userAppointmentData: (state) => state.appointment,
    userAppointmetCanceled: (state) => state.cancelAppointment,
    messageError: (state) => state.errorMessage,
    medicalRecord: (state) => state.medicalRecord,
  },

  actions: {
    async getUserAppointments({ commit }) {
      try {
        const res = await api.getAppointments();

        // Split data appointments into user and dependants appointments
        const [user, dependant] = res.data.reduce(
          ([user, dependant], elem) => elem.agendamento_proprio ? [
            [...user, elem], dependant] : [user, [...dependant, elem]
          ], [[], []]);

        commit('SET_USER_APPOINTMENTS', user);
        commit('SET_USER_DEPENDANTS_APPOINTMENTS', dependant);

        return Promise.resolve(res);
      } catch (e) {
        commit('SET_USER_APPOINTMENTS', null);
        commit('SET_USER_DEPENDANTS_APPOINTMENTS', null);

        return Promise.reject(e);
      }
    },

    async getUserAppointmentData({ commit }, appointmentId) {
      try {
        const res = await api.getAppointmentData(appointmentId);

        commit('SET_USER_APPOINTMENT', res.data);

        return Promise.resolve(res);
      } catch (e) {
        commit('SET_USER_APPOINTMENT', null);

        return Promise.reject(e);
      }
    },

    async cancelUserAppointment({ commit }, appointmentId) {
      try {
        const res = await api.cancelAppointment(appointmentId);

        commit('CANCEL_USER_APPOINTMENT', res.message);

        return Promise.resolve(res);
      } catch (e) {
        commit('CANCEL_USER_APPOINTMENT', null);

        return Promise.reject(e);
      }
    },

    clearErrorMessage({ commit }) {
      commit('SET_ERROR_MESSAGE', null);
    },

    fetchMedicalReport({ commit }, appointmentId) {
      return api.getMedicalRecord(appointmentId).then( response => {
        // response.data[0] because of endpoint returns a list.
        // One appointment will ever have only one medical report or none.
        commit('SET_MEDICAL_REPORT', response.data[0] ?? null);

        return Promise.resolve(response.data);
      }).catch((error) => {
        commit('SET_MEDICAL_REPORT', null);

        if(error.response.status !== 404) return Promise.reject(error.response);

        return null;
      });
    }
  },

  mutations: {
    SET_USER_APPOINTMENTS(state, payload){
        state.appointments = payload;
    },
    SET_USER_APPOINTMENT(state, payload){
      state.appointment = payload;
    },
    SET_USER_DEPENDANTS_APPOINTMENTS(state, payload){
        state.dependantsAppointments = payload;
    },
    CANCEL_USER_APPOINTMENT(state, payload) {
      state.cancelAppointment = payload;
    },
    SET_ERROR_MESSAGE(state, payload){
      state.errorMessage = payload;
    },
    SET_MEDICAL_REPORT(state, payload) {
      state.medicalRecord = payload;
    },
  }
};
