/* eslint-disable camelcase */
import api from '@/api/appointment';
import router  from '../../router';
import { setDependentsHeader } from '@/config/axios/dependentsIndex';

export default {
    state: {
        lists: {
            categories: null,
            services: null,
            places: null,
            availableDates: null,
            availableHoursDetails: null,
            availableProfissionals: null,
            vacanciesValues: null,
        },
        appointmentData: {
            category: null,
            service: null,
            appointmentType: null,
            place: null,
            profissional: null,
            date: null,
            hour: null,
            questionaryAnswers: []
        },
        dependentInfo: {
            dependents: null,
            singleDependent: null,
        },
        finishAppointment: {
            status: null,
            orientacoes: null,
            errorMsg: null,
        },
        backStepChange: false,
        currentStep: 1,
        stepsIsCompleted: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        },
        userInfo: null,
        sendAppointment: false,
        questionary: [],
    },

    getters: {
        availableDates: (state) => state.lists.availableDates,
        availableHoursDetails: (state) => state.lists.availableHoursDetails,
        availableProfissionals: (state) => state.lists.availableProfissionals,
        categories: (state) => state.lists.categories,
        services: (state) => state.lists.services,
        places: (state) => state.lists.places,
        appointmentData: (state) => state.appointmentData,
        selectedCategory: (state) => state.appointmentData.category,
        selectedService: (state) => state.appointmentData.service,
        selectedPlace: (state) => state.appointmentData.place,
        selectedProfissional: (state) => state.appointmentData.profissional,
        selectedDate: (state) => state.appointmentData.date,
        selectedHour: (state) => state.appointmentData.hour,
        selectedAppointmentType: (state) => state.appointmentData.appointmentType,
        finishAppointment: (state) => state.finishAppointment,
        availableDatesIsEmpty: (state) => state.lists.availableDates?.length === 0 ? true : false,
        backStepChange: (state) => state.backStepChange,
        vacanciesValues: (state) => state.lists.vacanciesValues,
        userAppointmentInfo: (state) => state.userInfo,
        currentStep: (state) => state.currentStep,
        stepsIsCompleted: (state) => state.stepsIsCompleted,
        sendAppointment: (state) => state.sendAppointment,
        dependentsList: (state) => state.dependentInfo.dependents,
        selectedDependent: (state) => state.dependentInfo.singleDependent,
        questionary: (state) => state.questionary,
        questionaryAnswers: (state) => state.appointmentData.questionaryAnswers,
    },

    actions: {
        async loadCategories({ commit }) {
            return api.getCategories()
            .then((response) => {
                let list = [...response.data];

                commit('SET_CATEGORIES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadServices({ commit, state }){
            return api.getServices(state.appointmentData.category?.id)
            .then((response) => {
                let list = [];

                if(response)
                    list = [...response.data];

                commit('SET_SERVICES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        fetchQuestionary({ commit }, questionaryId) {
            return api.getQuestionary(questionaryId)
            .then((response) => {
                commit('SET_QUESTIONARY_LIST', response.data.questoes);
            });
        },
        loadPlaces({ commit, state }) {
            return api.listServicePlaces(state.appointmentData.service.id)
            .then((response) => {
                let list = [...response.data];

                commit('SET_PLACES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadProfissionals({ commit, state }) {
            return api.listProfisOfAPlace(state.appointmentData.service.id,
                state.appointmentData.place.id)
                .then((response) => {
                    let list = [...response.data];

                    commit('SET_PROFISSIONALS_LIST', list);

                    return Promise.resolve(response);
                })
                .catch((error) => Promise.reject(error.response));
        },
        loadAvailableDates({ commit, state }){
            return api.datesWithAvailableScheduleOfPlace(state.appointmentData.service.id,
                state.appointmentData.place.id, state.appointmentData.appointmentType.id,
                state.appointmentData.profissional.id)
            .then((response) => {
                let list = response.data.map(el => el.dia);

                commit('SET_AVAILABLE_DATES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadAvailableHours({ commit, state }){
            return api.availableDateScheduleOfPlace(state.appointmentData.service.id,
                state.appointmentData.date, state.appointmentData.place.id,
                state.appointmentData.appointmentType.id, state.appointmentData.profissional.id)
            .then((response) => {
                let list = [...response.data];

                commit('SET_AVAILABLE_HOURS_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        async loadAvailableNumberOfVacancies({ state, commit }){
            return await api.numberOfAvailableHoursOfAPlace(state.appointmentData.service.id,
                state.appointmentData.appointmentType.id)
            .then((response) => {
                let list = [...response.data];

                commit('SET_AVAILABLE_NUMBER_OF_VACANCIES', list);

                return Promise.resolve(response);
            })
            .catch((error) =>{
                commit('SET_AVAILABLE_NUMBER_OF_VACANCIES', null);

                return Promise.reject(error.response);
            });
        },
        saveSelectedCategory ({ commit }, category){
            commit('SET_SELECTED_CATEGORY', category);
        },
        saveSelectedService({ commit }, service){
            commit('SET_SELECTED_SERVICE', { id: service.id, description: service.nome, orientacoes: service.orientacoes, orientacoes2: service.descricao });
        },
        saveSelectedPlace({ commit }, place){
            commit('SET_SELECTED_PLACE', place);
        },
        saveSelectedProfissional({ commit }, profissional){
            commit('SET_SELECTED_PROFISSIONAL', profissional);
        },
        saveSelectedAppointmentType({ commit }, appointmentType){
            commit('SET_SELECTED_APPOINTMENT_TYPE', appointmentType);
            // hotfix: esconder texto de nº de vagas até back-end corrigir o problema da API.
            // dispatch('loadAvailableNumberOfVacancies');
        },
        saveSelectedDate({ commit }, date){
            commit('SET_SELECTED_DATE', date);
        },
        saveSelectedHour({ commit }, hour){
            commit('SET_SELECTED_HOUR', { id: hour.id, description: hour.nome });
        },
        saveCurrentStep({ state, commit }, step) {
            if(step === 5 && !state.stepsIsCompleted[5])
                state.stepsIsCompleted[4] = true,
            state.stepsIsCompleted[5] = true;

            commit('SET_CURRENT_STEP', step);
        },
        saveQuestionary({ state, commit },
            {
                index: questao_id,
                value: resposta,
                description: questao_descricao,
                label: questao_label
            }) {
            const arrayIndex = state.appointmentData.questionaryAnswers.findIndex(e => e.questao_id === questao_id);

            if(arrayIndex >= 0) {
                if(resposta !== '') commit('UPDATE_MEDICAL_SCREENING', { index: arrayIndex, value: resposta });
                else commit('REMOVE_MEDICAL_SCREENING', arrayIndex);
            } else {
                commit('SET_MEDICAL_SCREENING', { questao_id, resposta, questao_descricao, questao_label });
            }
        },
        postAppointment({ state, commit } ,{ appointmentUser, idempotencyKey }){
            return api.postAppointment(state.appointmentData, appointmentUser, idempotencyKey)
            .then((response) => {
                commit('SET_USER_APPOINTMENT_INFO', appointmentUser);
                commit('SET_FINISH_STATUS',
                    {
                        status: 'Completed',
                        orientacoes: state.appointmentData.service.orientacoes,
                        description: state.appointmentData.service.orientacoes2,
                        birthdate: response.data.data_nascimento,
                        cpf: response.data.cpf,
                        date: response.data.data,
                        id: response.data.id,
                        name: response.data.nome,
                        phone: response.data.telefone,
                    });

                router.push({ name: 'AppointmentGeneralInfo' });

                return Promise.resolve(response);
            })
            .catch((error) => {
                commit('SET_USER_APPOINTMENT_INFO', null);

                return Promise.reject(error.response);
            });
        },
        resetAppointmentData({ commit }){
            commit('RESET_APPOINTMENT_DATA');
            commit('RESET_QUESTIONARY_DATA');
            commit('SET_SEND_APPOINTMENT', false);
        },
        resetAppointmentField({ commit }, field){
            commit('RESET_APPOINTMENT_FIELD', field);
        },
        resetFinishData({ commit }){
            commit('RESET_FINISH_DATA');
        },
        routeStepChange({ commit }, route){
            commit('SET_BACK_STEP_CHANGE', route);
        },
        resetAppointmentList({ commit }, field) {
            commit('RESET_APPOINTMENT_LIST_FIELD', field);
        },
        resetCompletedList({ commit }) {
            commit('RESET_COMPLETED_LIST');
        },
        setSendAppointment({ commit }, payload) {
            commit('SET_SEND_APPOINTMENT', payload);
        },
        dependentIsSelected({ commit }, payload) {
            commit('SET_SINGLE_DEPENDENT', payload);
        },
        fetchDependents({ commit }) {
            try {
              return api.getDependents().then( response => {
                  commit('SET_DEPENDENTS', response.data);

                  return Promise.resolve(response.data);
              }).catch((error) => {
                let status = error.response.status;

                commit('SET_DEPENDENTS', null);

                if(status !== 401) return Promise.reject(error.response);

                return null;
              });
            } catch (error) {
                commit('SET_DEPENDENTS', null);

                if(error.status !== 401) return Promise.reject(error.response);

                return null;
            }
        },
        fetchSingleDependents({ commit }, dependentInfo) {
              try {
                return api.getSingleDependent(dependentInfo.id).then( response => {
                  Promise.resolve(response);

                  commit('SET_SINGLE_DEPENDENT', { keycloak: response.data.dependent, data: dependentInfo.data });
                });
              } catch (error) {
                commit('SET_SINGLE_DEPENDENT', null);

                return Promise.reject(error.response);
              }
        },
        resetSingleDependents({ commit }) {
            commit('SET_SINGLE_DEPENDENT', null);
        },
    },

    mutations: {
        SET_CATEGORIES_LIST(state, list){
            state.lists.categories = list;
        },
        SET_SERVICES_LIST(state, services){
            state.lists.services = services;
            state.lists.availableDates = null;
            state.lists.vacanciesValues = null;
        },
        SET_QUESTIONARY_LIST(state, questionary) {
            state.questionary = questionary;
        },
        SET_PLACES_LIST(state, places){
            let list = places;

            state.lists.availableProfissionals = null;
            state.appointmentData.selectedProfissional = null;

            if(state.lists.vacanciesValues)
                state.lists.places = list.map(el => {
                    const item = state.lists.vacanciesValues?.find(e => e.unidade_id === el.id);
                    const vacancies = item?.total_horarios;
                    const maxVacancies = vacancies ?? '0';

                    return { ...el, maxVacancies };
                });
            else
                state.lists.places = list;
        },
        SET_PROFISSIONALS_LIST(state, payload) {
            state.lists.availableProfissionals = payload;
        },
        SET_AVAILABLE_DATES_LIST(state, dates){
            state.lists.availableDates = dates;
            state.lists.availableHoursDetails = null;
        },
        SET_AVAILABLE_HOURS_LIST(state, hours){
            state.lists.availableHoursDetails = hours;
        },
        SET_SELECTED_CATEGORY(state, category){
            if(state.appointmentData.category !== category) {
                state.appointmentData.category = category;
                state.stepsIsCompleted[1] = true;
                state.stepsIsCompleted[2] = true;
                state.stepsIsCompleted[3] = false;
                state.stepsIsCompleted[4] = false;
                state.stepsIsCompleted[5] = false;
                state.appointmentData.service = null;
            }
        },
        SET_SELECTED_SERVICE(state, service){
            state.appointmentData.service = service;
            state.appointmentData.appointmentType = null;
            state.lists.places = null;
            state.lists.vacanciesValues = null;
            state.stepsIsCompleted[3] = false;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_PROFISSIONAL(state, profissional){
            if(state.appointmentData.profissional !== profissional)
                state.lists.availableDates = null,
                state.lists.availableHoursDetails = null;
            state.appointmentData.profissional = profissional;
        },
        SET_SELECTED_APPOINTMENT_TYPE(state, type){
            state.appointmentData.appointmentType = type;
            state.lists.vacanciesValues = null;
            state.stepsIsCompleted[3] = true;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_PLACE(state, value){
            state.appointmentData.place = value;
            state.appointmentData.date = null;
            state.appointmentData.profissional = null;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_DATE(state, date){
            state.appointmentData.date = date;
            state.appointmentData.hour = null;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_HOUR(state, hour){
            state.appointmentData.hour = hour;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_FINISH_STATUS(state, payload){
            state.finishAppointment.status = payload.status;
            state.finishAppointment.orientacoes = payload.orientacoes;
            state.finishAppointment.description = payload.description;
            state.finishAppointment.errorMsg = payload.errorMsg;

            state.finishAppointment.birthdate = payload.birthdate;
            state.finishAppointment.cpf = payload.cpf;
            state.finishAppointment.date = payload.date;
            state.finishAppointment.id = payload.id;
            state.finishAppointment.name = payload.name;
            state.finishAppointment.phone = payload.phone;
        },
        SET_STEP_COMPLETED_STATUS(state, { step, value }){
            state.stepsIsCompleted[step] = value;
        },
        SET_AVAILABLE_NUMBER_OF_VACANCIES(state, payload) {
            state.lists.vacanciesValues = payload;
            if(payload) {
                state.lists.places = state.lists.places?.map(el => {
                    const item = payload?.find(e => e.unidade_id === el.id);
                    const vacancies = item?.total_horarios;
                    const maxVacancies = vacancies ?? '0';

                    return { ...el, maxVacancies };
                });
            }
        },
        RESET_APPOINTMENT_FIELD(state, field) {
            state.appointmentData[field] = null;
        },
        RESET_APPOINTMENT_DATA(state){
            Object.keys(state.appointmentData).forEach((key)=>{
                if(key === 'questionaryAnswers') state.appointmentData[key] = [];
                else state.appointmentData[key] = null;
            });
            Object.keys(state.lists).forEach((key) => {
                state.lists[key] = null;
            });
        },
        RESET_QUESTIONARY_DATA(state){
            state.questionary = [];
        },
        RESET_APPOINTMENT_LIST_FIELD(state, field) {
            state.lists[field] = null;
        },
        RESET_FINISH_DATA(state){
            Object.keys(state.finishAppointment).forEach((key)=>{
                state.finishAppointment[key] = null;
            });
        },
        RESET_COMPLETED_LIST(state){
            [1,2,3,4,5].forEach(el=> state.stepsIsCompleted[el] = false);
        },
        RESET_COMPLETED_LIST_STEP(state, step){
            state.stepsIsCompleted[step] = false;
        },

        SET_BACK_STEP_CHANGE(state, route){
            state.backStepChange = route;
        },

        SET_SEND_APPOINTMENT(state, payload){
            state.sendAppointment = payload;
        },

        SET_USER_APPOINTMENT_INFO(state, payload){
          state.userInfo = payload;
          state.stepsIsCompleted[4] = true;
          state.stepsIsCompleted[5] = true;
        },

        SET_CURRENT_STEP(state, payload){
            state.currentStep = payload;
        },

        SET_DEPENDENTS(state, payload) {
            state.dependentInfo.dependents = payload;
        },

        SET_SINGLE_DEPENDENT(state, payload) {
            if (payload !== null) {
                setDependentsHeader(payload.keycloak.access_token);
            }

            state.dependentInfo.singleDependent = payload;
            state.stepsIsCompleted[1] = true;
        },

        SET_MEDICAL_SCREENING(state, payload) {
            state.appointmentData.questionaryAnswers.push(payload);
        },

        UPDATE_MEDICAL_SCREENING(state, payload) {
            state.appointmentData.questionaryAnswers[payload.index].resposta = payload.value;
        },

        REMOVE_MEDICAL_SCREENING(state, index) {
            state.appointmentData.questionaryAnswers.splice(index, 1);
        }
    }
};
